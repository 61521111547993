import { getApi, postApi, putApi } from "../apis";

/**
 * Fetches all client names from the API.
 */
export const getAllClientName = () => getApi(`client/all/clientname`);

/**
 * Retrieves client information from the API based on the client ID.
 */
export const getClientByID = (clientID) =>
  getApi(`Client?ClientId=${clientID}`);

// get Contact By id
export const getViewContactList = (contactId) =>
  getApi(`Client/Contact?clientContactId=${contactId}`);

/**
 * Retrieves the contact name of all clients with the given ID from the API.
 */
export const getAllClientContactName = (id) =>
  getApi(`Client/all/contactname?clientId=${id}`);

/**
 * Retrieves a list of views for a specific client ID from the API.
 */
export const getViewList = (clientID) => getApi(`Client?ClientId=${clientID}`);

// for list Of client  data
export const getAllClientList = () => getApi(`client/all/clientname`);

// list with Filterdata

export const getFilteredClientList = (count, page, searchValue, clientName, groupName) => {
  const data = {
    clientName,
    groupName,
  };

  return postApi(
    `client/all/contact?search=${searchValue}&page=${page}&count=${count}`,
    { ...data }
  );
};

/**
 * Creates a new client by sending a POST request to the API endpoint.
 */
export const createNewClient = (data) => postApi(`client`, data);

/**
 * Creates a new client contact list by sending a POST request to the API endpoint.
 */
export const createNewClientConatctList = (data) => {
  const { id, contact } = data;
  return postApi(`client/Contact?ClientId=${id}`, contact);
}


/**
 * Deletes client contact data by making a PUT request to the API endpoint.
 */
export const DeleteClientConatctData = (data) => {
  const { id, ...post } = data;
  return putApi(`client/contact?clientId=${id}`, post);
};

/**
 * Edit client data by sending a PUT request to the API with the updated client information.
 */
export const editClientData = (data) => {
  const { id, ...post } = data;
  return putApi(`Client?ClientId=${id}`, post);
};
/**
 * Edits the contact data for a client with the given ID.
 */
export const editClientConatctData = (data) => {
  const { id, contact } = data;
  return putApi(`Client/contact?clientId=${id}`, contact);
};


/**
 * Sends a POST request to the server to check for duplicate email addresses in the client contacts.
 */
export const dublicateEmail = (data) => {
  return postApi(`client/Contact/validation`, data);
}


export const clientBulkUpload = (data) => {
  return postApi("client/bulkupload", data);
}