import React from "react";
import { BasicModal } from "../Modal/Modal";
import { UseDocumentUpdateContext } from "../../utils/hooks";
import { Loader } from "../Loader";
import styles from "./FilePreviewModal.module.scss";
import CancelIcon from "@mui/icons-material/Cancel";

const FilePreviewModal = ({ open, handleClose, fileData }) => {
  const { useGetFileUrl } = UseDocumentUpdateContext();
  const { data: fileUrlData, isLoading: isFileUrlLoading } =
    useGetFileUrl(fileData);

  const fileType = fileUrlData?.fileName?.split(".").pop().toLowerCase();
  const filePath = fileUrlData?.fileStorageReference;

  const imageTypes = ["png", "jpg", "jpeg", "jfif"];

  const getViewerSrc = () => {
    if (fileType && fileType === "csv") {
      return `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
        filePath
      )}`;
    } else if (fileType && fileType === "pdf") {
      return filePath;
    } else {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        filePath
      )}&embedded=true`;
    }
  };
  return (
    <BasicModal open={open} fullScreen>
      {isFileUrlLoading ? (
        <Loader />
      ) : (
        <div className={styles.modalContent}>
          <div
            className={
              fileType && fileType === "csv" ? styles.maskBackground : ""
            }
          ></div>

          <CancelIcon onClick={handleClose} />
          {imageTypes.includes(fileType) ? (
            <img src={filePath} alt="preview" />
          ) : (
            <iframe
              style={{ width: "100vw", height: "calc(100vh - 46px)" }}
              src={getViewerSrc()}
              title="file preview"
            ></iframe>
          )}
        </div>
      )}
    </BasicModal>
  );
};

export default FilePreviewModal;
