import React, { useEffect } from 'react';
import DocumentRequested from './Document-requested';
import CustomSidebarComponent from './CustomSidebarComponent';
import { useAuth, useDocumentListContext } from '../../utils/hooks';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import DocumentRequestModal from './DocumentRequestModal';
import styles from './document-requested.module.scss';
import RequestIcon from '../../assets/Icons/RequestIcon';

export default function Documentrequestedmain() {
  const [qParams] = useSearchParams();
  const {
    useGetDocumentRequestList,
    useGetRequestQuestionList,
    mutateDocumentSave,
    isTicketDocumentSaveLoading,
    openModal,
    isClientUpdateAnswerLoading,
  } = useDocumentListContext();
  const { useGetConfigData } = useAuth();

  const token = qParams.get('token');
  const organizationCode = qParams.get('code');

  const { data: userConfigData, isLoading: isUserConfigDataLoading } =
    useGetConfigData(organizationCode);

  const {
    data: documentRequestList,
    isLoading,
    isRefetching,
  } = useGetDocumentRequestList(token);

  const { data: requestQuestionList, isLoading: isQuestionListLoading } =
    useGetRequestQuestionList(token);

  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
  });

  /**
   * useEffect hook that resets the ticketIDRDocuments field with the list of documents that have not been received.
   * @param {Function} reset - Function to reset the form fields.
   * @param {Object} documentRequestList - The list of document requests.
   * @returns None
   */
  useEffect(() => {
    reset({
      ticketIDRDocuments:
        documentRequestList?.doclist?.filter((doc) => !doc.received) || [],
      idrQuestions:
        requestQuestionList?.answers?.map((item) => ({
          ...item,
          answers: item.answers || '',
        })) || [],
    });
  }, [documentRequestList, requestQuestionList]);

  const { fields: documentList } = useFieldArray({
    control,
    name: 'ticketIDRDocuments',
  });

  const { fields: questionList } = useFieldArray({
    control,
    name: 'idrQuestions',
  });

  /**
   * Saves the uploaded documents based on the provided data.
   * @param {{object}} data - The data containing information about the uploaded documents.
   * @returns None
   */
  const onSave = (data) => {
    const filterUploadedDocument =
      data.ticketIDRDocuments?.map((documentType) => ({
        ticketDocumentID: documentType.ticketDocumentID || 0,
        received: documentType.received,
        isActive: true,
        status: 'Active',
        document: documentType.multipleDocumentList
          .filter((document) => !document.ticketIDRMultipleDocumentID)
          .map((document) => ({
            ...document,
            ticketIDRMultipleDocumentID: 0,
            isActive: true,
          })),
      })) || [];

    const finalData = {
      token,
      list:
        filterUploadedDocument?.filter(
          (documentType) => documentType.document.length > 0
        ) || [],
      answers: {
        answers: data.idrQuestions?.map((item) => ({
          ...item,
          answered: !!item.answers,
          isActive: true,
        })),
      },
    };
    if (filterUploadedDocument.length > 0) {
      if (finalData.list.length === 0) {
        toast.error('Please upload atleast one new document');
      } else {
        mutateDocumentSave(finalData);
      }
    } else {
      mutateDocumentSave(finalData);
    }
  };

  if (
    isLoading ||
    isRefetching ||
    isQuestionListLoading ||
    isUserConfigDataLoading
  ) {
    return <Loader />;
  }

  if (!documentRequestList?.success && !isLoading) {
    return (
      <div>
        <DocumentRequestModal
          open
          title="Link Expired"
          message="Please contact the sender to request a new link"
        />
      </div>
    );
  }

  return (
    <div className={styles.documentRequestBody}>
      <CustomSidebarComponent
        logo={userConfigData?.result?.logoURLReference}
        name={userConfigData?.result?.name}
      />
      <div className={styles.headerComponent}>
        <p> {userConfigData?.result?.name} - PBC Request</p>
        <RequestIcon />
      </div>
      <form onSubmit={handleSubmit(onSave)}>
        <DocumentRequested
          isTicketDocumentSaveLoading={
            isTicketDocumentSaveLoading || isClientUpdateAnswerLoading
          }
          documentList={documentList || []}
          questionList={questionList || []}
          control={control}
          organizationCode={organizationCode}
          taxYear={documentRequestList?.taxYear}
        />
      </form>
      {openModal && (
        <DocumentRequestModal
          open={openModal}
          title="Success"
          message="Document request updated successfully"
          type="success"
        />
      )}
    </div>
  );
}
