export const pattern = {
  phoneRegex: /^\+?[0-9]{1,3}?[-. ]?\(?[0-9]{1,3}\)?[-. ]?[0-9]{3,4}[-. ]?[0-9]{4}$/,
  email:
    /^(?!^\.)^(?!.*\.\.)(?!.*\.$)(?!.*\.web$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  noSpace: /^[^\s]/,
  // name: /^[a-zA-Z&.,'\s]+$/,
  name: /^[a-zA-Z0-9$&,._ -]+$/,
  phoneFieldRegex: /^[0-9-+]*$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;"'<>,.?\\/~`|-₹=-])[A-Za-z\d!@#$%^&*()_+{}[\]:;"'<>,.?\\/~`|-₹=-]{8,}$/,
};
