import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";

// Component imports
import PrivateRoute from "./Privateroute";
import PublicRoute from "./Publicroute";
import Documentrequestedmain from "../pages/Document-requested";
// login components imports.
import Organizationwrapper from "../pages/Organisation";
import LoginWrapper from "../pages/Login";
import AuthCallbackPage from "../pages/Login/Auth-callback";
import Forgetpassword from "../pages/Forget-password";
import Resetpasswordwrapper from "../pages/Reset-password";
// dashboard component import.
import Dashboardmain from "../pages/Dashboard";

// ticket management component import
import TicketManagement from "../pages/TicketManagement";
// view ticket component import.
import Viewticketwrapper from "../pages/TicketManagement/Viewticket";

// user management component import
import UserManagement from "../pages/UserManagement";
// profile component import
import Userprofile from "../pages/Profile";

//Client/group component import
import ClientManagement from "../pages/ManageClients";
import { CreateOrEditTicket } from "../pages/TicketManagement/CreateOrEditTicket";
import { AddOrEditUser } from "../pages/UserManagement/AddNewUser";
import { AddOrEditClient } from "../pages/ManageClients/AddOrEditClient";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { useCustomerDetailsContext } from "../utils/hooks/Auth/Organization";
import Groups from "../pages/ManageClients/Groups";
import NotFoundPage from "../components/404Page";
import SettingsWrapper from "../pages/Setting";
import FilePreview from "../pages/FilePreview";

// archive component.
import Archive from "../pages/Archive";
import OtpLogin from "../pages/OtpLogin";

const AppRoutes = () => {
  const { storeSsoStatus } = useCustomerDetailsContext();

  const pageUrl = window.location.protocol + "//" + window.location.host;
  const redirectURI = `${pageUrl}/login/auth_callback`;
  const msalConfig = {
    auth: {
      clientId: storeSsoStatus?.ssOdetails?.clientId,
      authority: "https://login.microsoftonline.com/common",
      redirectUri: redirectURI,
    },
  };

  const pca = new PublicClientApplication(msalConfig);
  pca.initialize();

  return (
    <MsalProvider instance={pca}>
      <Routes>
        {/* public routes */}
        <Route
          path="/"
          element={
            <PublicRoute>
              <Organizationwrapper />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <LoginWrapper />
            </PublicRoute>
          }
        />
        <Route
          path="/auth_callback"
          element={
            <PublicRoute>
              <AuthCallbackPage />
            </PublicRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <SettingsWrapper />
            </PrivateRoute>
          }
        />
        <Route
          path="/forgetpassword"
          element={
            <PublicRoute>
              <Forgetpassword />
            </PublicRoute>
          }
        />
        <Route
          path="/resetPassword"
          element={
            <PublicRoute>
              <Resetpasswordwrapper />
            </PublicRoute>
          }
        />
        <Route
          path="/otpLogin"
          element={
            <PublicRoute>
              <OtpLogin />
            </PublicRoute>
          }
        />
        <Route path="/document-request" element={<Documentrequestedmain />} />

        {/* private routes */}

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboardmain />
            </PrivateRoute>
          }
        />
        <Route path="/ticket" element={<Outlet />}>
          <Route
            index
            element={
              <PrivateRoute>
                <TicketManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="add"
            element={
              <PrivateRoute>
                <CreateOrEditTicket type="add" />
              </PrivateRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <PrivateRoute>
                <CreateOrEditTicket type="edit" />
              </PrivateRoute>
            }
          />
          <Route
            path="view/:id"
            element={
              <PrivateRoute>
                <Viewticketwrapper />
              </PrivateRoute>
            }
          />
          <Route
            path="addUser"
            element={
              <PrivateRoute>
                <AddOrEditUser type="add" />
              </PrivateRoute>
            }
          />
          <Route
            path="addClient"
            element={
              <PrivateRoute>
                <AddOrEditClient type="add"/>
              </PrivateRoute>
            }
          />
        </Route>

        {/* user Routes */}
        <Route path="/user" element={<Outlet />}>
          <Route
            index
            element={
              <PrivateRoute>
                <UserManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="add"
            element={
              <PrivateRoute>
                <AddOrEditUser type={"add"} />
              </PrivateRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <PrivateRoute>
                <AddOrEditUser type={"edit"} />
              </PrivateRoute>
            }
          />
        </Route>
        {/* client Routes */}

        <Route path="/client" element={<Outlet />}>
          <Route
            index
            element={
              <PrivateRoute>
                <ClientManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="add"
            element={
              <PrivateRoute>
                <AddOrEditClient type={"add"} />
              </PrivateRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <PrivateRoute>
                <AddOrEditClient type={"edit"} />
              </PrivateRoute>
            }
          />
          <Route
            path="editcontact/:contactId"
            element={
              <PrivateRoute>
                <AddOrEditClient type={"editContact"} />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="/group"
          element={
            <PrivateRoute>
              <Groups />
            </PrivateRoute>
          }
        />
        {/* -------- */}
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Userprofile />
            </PrivateRoute>
          }
        />
        <Route
          path="/archive"
          element={
            <PrivateRoute>
              <Archive />
            </PrivateRoute>
          }
        />

        <Route path="/login/auth_callback" element={<Outlet />} />
        <Route path="/preview" element={<FilePreview />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </MsalProvider>
  );
};

export default AppRoutes;
