import React from "react";
import PropTypes from "prop-types";
import styles from "./ModuleWrapper.module.scss";
import Layout from "../../layouts";

const ModuleWrapper = ({ moduleHeader, moduleImage, children }) => {
  return (
    <Layout>
      <div className={styles.moduleContainer}>
        <p className={styles.moduleHeader}>
          {moduleHeader}
          <span>
            {moduleImage && <img src={moduleImage} alt="module icon" />}
          </span>
        </p>
        {children}
      </div>
    </Layout>
  );
};

ModuleWrapper.propTypes = {
  moduleHeader: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ModuleWrapper;
