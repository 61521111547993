import React, { useState, useEffect } from "react";
import CustomTable from "../../components/CustomTable";
import SectionHeader from "../../components/SectionHeader";
import { SearchableDropDown } from "../../components/SearchableDropdown";
import { EditUnderLineIcon } from "../../assets/Icons/EditIcon";
import ThreeDotMenu from "../../components/ThreeDotMenu";
import { ThreeDots } from "../../assets/Icons/ThreeDot";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { IconButton, Box } from "@mui/material";
import styles from "./Manageclients.module.scss";
import { useClient } from "../../utils/hooks/Client";
import { useNavigate } from "react-router";
import { useDebounce } from "../../utils/useDebounce";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CustomButton from "../../components/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import BulkUploadCsvFiles from "./Bulkupload";
import { useClientBulkUpload } from "../../utils/hooks/Client/Bulk-upload";
import { BasicModal } from "../../components/Modal/Modal";
import ModalContent from "./Bulk-upload-modal";
import { UseGroupContext } from "../../utils/hooks/Group";
import { ModifyIcon } from "../../assets/Icons/ModifyIcon";
import { TrashIcon } from "../../assets/Icons/TrashIcon";
import { setHeaderProp } from "../../utils/helper";
import DeleteModal from "../../components/DeleteModal";
import ErrorModal from "../../components/DeleteModal/ErrorModal";

const ClientManagementTable = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [clientRowData, setClientRowData] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(10);
  const [actionItem, setActionItem] = useState({
    deleteStatus: false,
    clientName: "",
  });

  const {
    setViewDetails,
    setClientId,
    useGetAllClientList,
    useGetAllClientName,
    handleDeleteClient,
    groupName,
    setGroupName,
    openDeleteModal,
    setOpenDeleteModal,
    clientArchiveErrorText,
    setClientArchiveErrorText,
    isClientArchiveLoading,
  } = useClient();

  const navigate = useNavigate();
  const { bulkUploadData } = useClientBulkUpload();
  const { useGetAllGroup } = UseGroupContext();

  const { data } = useGetAllGroup();

  function fetchGroupNames() {
    /* Filters and maps the group data to create a new array of objects with 'label' and 'value' properties.
     */
    const groupfilterdData = data?.results?.map((item) => ({
      label: item?.groupName,
      value: item?.groupID,
    }));

    return groupfilterdData;
  }

  const threeDotMenuItem = [
    {
      name: "View Details",
      onClickFunction: () => {
        setAnchorEl(null);
        setViewDetails(true);
        setClientId(clientRowData?.clientId);
      },
      icon: (
        <RemoveRedEyeOutlinedIcon
          sx={{ color: "#565E6DFF", fontSize: "20px" }}
        />
      ),
    },
    {
      name: "Edit Client",
      onClickFunction: () => {
        setAnchorEl(null);

        navigate(`edit/${clientRowData?.clientId}`);
      },
      icon: <EditUnderLineIcon />,
    },
    {
      name: "Edit this Contact",
      onClickFunction: () => {
        setAnchorEl(null);

        navigate(`editcontact/${clientRowData?.clientContactId}`);
      },
      icon: <EditUnderLineIcon />,
    },
    {
      name: "Delete Client",
      onClickFunction: () => {
        setAnchorEl(null);
        setOpenDeleteModal(true);
      },
      icon: <DeleteOutlineOutlinedIcon />,
    },
  ];
  const handleOpenRowMenu = (event, row) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    setClientRowData(row);
    setActionItem((prevState) => ({
      ...prevState,
      deleteStatus: false,
      clientName: "",
    }));
  };

  const handleCloseThreeDotMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const debouncedSearchValue = useDebounce(searchValue, 2000);
  const { data: allClientData, isLoading: isClientDataLoading } =
    useGetAllClientList(
      page + 1,
      count,
      debouncedSearchValue,
      selectedValue,
      groupName
    );

  const { data: allClientListname, isLoading: isAllClientListLoading } =
    useGetAllClientName();

  /**
   * Filters and maps the client data to create a new array of objects with 'label' and 'value' properties.
   */

  const clientfilterdData = allClientListname?.results?.map((item) => ({
    label: item?.name,
    value: item?.name,
  }));

  useEffect(() => {
    if (page > 0) {
      setPage(0);
    }
  }, [debouncedSearchValue, selectedValue]);

  let changeBackGround = 7,
    prev = 0,
    toogle = 0;
  const setCellProp = (e) => {
    if (prev === changeBackGround) {
      prev = 0;
      toogle++;
    }
    prev++;

    return {
      style: {
        left: "0",
        background: toogle % 2 === 0 ? "#e5f5fc" : "#ffffff",
        zIndex: 100,
        padding: "0!important",
        textTransform: "capitalize",
        textAlign: "center",
        color: "var(--primary-color)",
        marginLeft: "10px",
        marginRight: "10px",
        fontWeight: "500",
      },
    };
  };

  /**
   * the function below is triggering the download of the csv template. it creates an a element  with href attribute and click event.
   * @returns
   */

  const handleCsvDownload = () => {
    const fileUrl = `${process.env.PUBLIC_URL}/client-template_050208299.csv`;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.download = "upload_client_template.csv";
    link.click();
  };
  
  const columns = [
    // {
    //   name: "clientId",
    //   label: "Client Id",

    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       const rowData = allClientData?.results[tableMeta.rowIndex];
    //       return <p>#{rowData.clientId}</p>;
    //     },

    //     setCellProps: setCellProp,
    //     setCellHeaderProps: setHeaderProp,
    //   },
    // },
    {
      name: "clientName",
      label: "Client Name",

      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = allClientData?.results[tableMeta.rowIndex];
          return <p>{rowData.clientName}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "groupName",
      label: "Group Name",

      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = allClientData?.results[tableMeta.rowIndex];
          return <p>{rowData.groupName || "-"}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "firstName",
      label: "Contact Person First Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = allClientData?.results[tableMeta.rowIndex];
          return <p>{rowData.firstName}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "lastName",
      label: "Contact Person Last Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = allClientData?.results[tableMeta.rowIndex];
          return <p>{rowData.lastName}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "phone",
      label: "Phone Number",

      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = allClientData?.results[tableMeta.rowIndex];
          return <p>{rowData.phone || "-"}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "email",
      label: "Client Email Address",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = allClientData?.results[tableMeta.rowIndex];
          return <p>{rowData.email}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = allClientData?.results[tableMeta.rowIndex];
          return (
            <>
              <div className={styles.actionItemWrapper}>
                <IconButton
                  onClick={() => {
                    navigate(`edit/${rowData?.clientId}`);
                  }}
                >
                  <ModifyIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setActionItem((prevState) => ({
                      ...prevState,
                      deleteStatus: true,
                      clientName: rowData.clientName,
                    }));
                    setClientRowData(rowData);
                    setOpenDeleteModal(true);
                  }}
                >
                  <TrashIcon />
                </IconButton>
                <IconButton onClick={(e) => handleOpenRowMenu(e, rowData)}>
                  <ThreeDots />
                </IconButton>
                <ThreeDotMenu
                  // id={`User_table_features_${row._id}`}
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={handleCloseThreeDotMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  menuItem={threeDotMenuItem}
                />
              </div>
            </>
          );
        },
        sort: false,
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
  ];

  const handleButtonClick = () => {
    navigate("add");
  };
  const handleChangePage = (_page) => {
    setPage(_page);
  };
  const handleChangePageCount = (_pageCount) => {
    setCount(_pageCount);
    const totalPages = Math.ceil(
      allClientData?.totalActiveRecords / _pageCount
    );

    if (page >= totalPages) {
      setPage(totalPages - 1);
    }
  };
  /**
   * Handles the click event for deleting a client. It prepares the necessary data
   * and calls the handleDeleteClient function to delete the client. It then closes
   * the delete modal.
   */
  const handleDeleteClick = () => {
    const data = {
      clientName: clientRowData?.clientName,
      id: clientRowData?.clientId,
    };
    handleDeleteClient(data);
  };

  function renderNoDataText() {
    if (selectedValue || debouncedSearchValue || groupName) {
      return "No Client Contacts Found";
    } else {
      return "No Client Added";
    }
  }

  return (
    <Box className={styles.cliet_management_dashboard}>
      <BasicModal open={bulkUploadData.showBulkUploadModal}>
        <ModalContent />
      </BasicModal>
      <SectionHeader
        buttonText="Add New Client"
        onButtonClick={handleButtonClick}
        searchText={searchValue}
        handleSearchValue={(e) => {
          setSearchValue(e.target.value);
        }}
      >
        <Box className={styles.Section_container}>
          <Box className={styles.Section_dropdown_container}>
            <SearchableDropDown
              placeholder="Client Name"
              label=""
              value={
                clientfilterdData?.find(
                  (item) => item.label === selectedValue
                ) || ""
              }
              onChange={(e) => {
                setSelectedValue(e?.label);
              }}
              isClearable
              isLoading={isAllClientListLoading}
              options={clientfilterdData}
              getOptionLabel={(option) => option?.label}
            />
            <SearchableDropDown
              placeholder="Group Name"
              label=""
              value={
                fetchGroupNames()?.find((item) => item.label === groupName) ||
                ""
              }
              onChange={(e) => {
                setGroupName(e?.label);
              }}
              isClearable
              isLoading={isAllClientListLoading}
              options={fetchGroupNames()}
              getOptionLabel={(option) => option?.label}
            />
          </Box>
        </Box>
      </SectionHeader>

      <Box className={styles.bulkUploadBtnContainer}>
        <Box className={styles.btnWrapper}>
          <CustomButton onClick={handleCsvDownload} style={{ width: "220px" }}>
            Download Template <DownloadForOfflineIcon />
          </CustomButton>
        </Box>
        <Box className={styles.btnWrapper}>
          <BulkUploadCsvFiles />
        </Box>
      </Box>

      <CustomTable
        data={allClientData?.results || []}
        columns={columns}
        isLoading={isClientDataLoading}
        noDataText={renderNoDataText()}
        pagination
        totalCount={allClientData?.totalActiveRecords || 0}
        paginationServer
        page={page}
        countPerPage={count}
        onChangePage={handleChangePage}
        onChangeCountPerPage={handleChangePageCount}
      />
      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          handleCancel={() => {
            setOpenDeleteModal(false);
            setActionItem((prevState) => ({
              ...prevState,
              deleteStatus: false,
              clientName: "",
            }));
          }}
          handleDeleteClick={handleDeleteClick}
          primaryText={`Are you sure want to delete this client-"${
            actionItem.deleteStatus
              ? actionItem.clientName
              : clientRowData?.clientName
          }"?`}
          secondaryText="All the tickets and contacts related to this client will be deleted."
          isButtonDisabled={isClientArchiveLoading}
        />
      )}
      {clientArchiveErrorText && (
        <ErrorModal
          open={!!clientArchiveErrorText}
          handleClose={() => setClientArchiveErrorText("")}
          errorText={clientArchiveErrorText}
        />
      )}
    </Box>
  );
};

export default ClientManagementTable;
