import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { SearchableDropDown } from "../../../components/SearchableDropdown";
import styles from "./CreateTicketModal.module.scss";
import CustomButton from "../../../components/Button";
import { PlusIcon } from "../../../assets/Icons/PlusIcon";
import DocumentRequested from "./DocumentsRequested";
import DocumentTypeModal from "./DocumentTypeModal";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../layouts";
import FormConatiner from "./FormConatiner";
import moduleImage from "../../../assets/Images/dashboard-images/frame.svg";
import {
  useAuth,
  useDocumentListContext,
  useMaster,
  useTicket,
  useUser,
} from "../../../utils/hooks";
import { Loader } from "../../../components/Loader";
import { toast } from "react-toastify";
import { initialTicketData, yearsArray } from "../../../utils/contants";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import BulkUpload from "./BulkUpload";
import AddQuestionModal from "./AddQuestionModal";
import LoadingOverlay from "../../../components/LoadingOverlay";
import CalendarIcon from "../../../assets/Icons/CalendarIcon";

export const CreateOrEditTicket = ({ type }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const {
    handleCreateTicket,
    isCreateTicketLoading,
    handleUpdateTicket,
    isUpdateTicketLoading,
    isDeleteTicketDocumentLoading,
    useGetTicketQuestions,
  } = useTicket();
  const { useGetTicketById } = useDocumentListContext();
  const {
    data: ticketData,
    isLoading: isTicketDataLoading,
    isRefetching,
  } = useGetTicketById(id);
  const [openDocumentTypeModal, setOpenDocumentTypeModal] = useState(false);
  const [openAddQuestionModal, setOpenAddQuestionModal] = useState(false);
  const { useGetAllUser } = useUser();
  const { useGetDocumentType } = useMaster();

  const { data: documentTypeList, isLoading: isDocumentTypeListLoading } =
    useGetDocumentType();

  const { data: allUserList, isLoading: isAllUserListLoading } =
    useGetAllUser();

  const { data: ticketQuestionsList, isLoading: isTicketsQuestionListLoading } =
    useGetTicketQuestions(id);

  /**
   * Maps over a list of users and creates an array of objects with label, email, and value properties.

   */
  const assigneeOption = allUserList?.results?.map((item) => ({
    label: `${item.fName} ${item.lName}`,
    email: item?.mail,
    value: item.userID,
  }));

  /**
   * Initializes the form with the specified mode and default values.
   * @param {{string}} mode - The mode of the form (e.g., "all").
   * @param {{object}} defaultValues - The initial values for the form fields.
   * @returns An object containing functions for handling form submission, controlling form fields, setting field values, resetting the form, and watching form field changes.
   */
  const { handleSubmit, control, setValue, reset, watch } = useForm({
    mode: "all",
    defaultValues: initialTicketData,
  });

  /**
   * Destructures the 'fields' and 'remove' properties from the result of calling the useFieldArray hook
   * with the provided control and name parameters.
   * @param {{control}} control - The control object from the useForm hook.
   * @param {{string}} name - The name of the field array to be used.
   * @returns An object containing the 'documentList' and 'remove' properties.
   */
  const { fields: documentList, remove } = useFieldArray({
    control,
    name: "ticketIDRDocuments",
  });

  /**
   * Destructures the 'fields' and 'remove' properties from the result of calling the useFieldArray hook
   * with the provided control and name parameters.
   * @param {{control}} control - The control object from the useForm hook.
   * @param {{string}} name - The name of the field array to be used.
   * @returns An object containing the 'documentList' and 'remove' properties.
   */
  const { fields: questionList, remove: removeQuestion } = useFieldArray({
    control,
    name: "idrQuestions",
  });

  const modalHeader = type === "edit" ? "Edit Requests" : "Add Requests";

  /**
   * Handles form submission by checking if there are any ticket ID documents attached.
   * If documents are attached, it either creates a new ticket or updates an existing ticket based on the type.
   * If no documents are attached, it displays an error message using toast.
   * @param {{object}} data - The form data containing ticket information.
   * @returns None
   */
  const onSubmit = (data) => {
    const oldAssignee = ticketData?.user.map((item) => item.userId) || [];
    const newAssignee = data.assignee.map((item) => item.value);
    const assignee = data.assignee
      .filter((item) => !oldAssignee.includes(item.value))
      .map((item) => ({
        id: 0,
        ticketOwnerID: item.value,
        ticketId: id,
        isActive: true,
      }));

    const deletedAssignee = ticketData?.user
      .filter((item) => !newAssignee.includes(item.userId))
      .map((item) => ({
        id: item.ticketownerid,
        ticketOwnerID: item.userId,
        ticketId: id,
        isActive: false,
      }));

    if (data.ticketIDRDocuments.length !== 0) {
      if (type === "add") {
        handleCreateTicket(data);
      } else {
        handleUpdateTicket({
          ...data,
          assignee: [...assignee, ...deletedAssignee],
        });
      }
    } else {
      toast.error("Please add at least one document");
    }
  };

  /**
   * useEffect hook that triggers when the 'id' and 'ticketData' dependencies are present.
   * It formats the ticket details data and sets it for editing.
   * @returns None
   */
  useEffect(() => {
    if (id && ticketData && ticketQuestionsList) {
      const editTicketDetails = {
        ticketID: ticketData?.ticketID,
        client: {
          label: ticketData?.client?.clientName,
          value: ticketData?.client?.clientID,
        },
        contactPerson: {
          label: `${ticketData?.clientContact?.firstName} ${ticketData?.clientContact?.lastName}`,
          value: ticketData?.clientContact?.clientContactID,
        },
        emailId: ticketData?.clientContact?.email,
        dueDate: new Date(ticketData?.dueDate || null),
        status: {
          label: ticketData?.status,
          value: ticketData?.status,
        },
        assignee: ticketData?.user?.map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          email: item?.email,
          value: item.userId,
        })),
        taxYear: ticketData?.taxYear ? {
          label: ticketData?.taxYear,
          value: ticketData?.taxYear,
        } : null,
        ticketIDRDocuments: ticketData?.ticketIDRDocuments?.map(
          (ticketIDRDocument) => ({
            ...ticketIDRDocument,
            received: ticketIDRDocument?.idrDocumentReceived,
            elseWhere: ticketIDRDocument?.elsewhere,
            documents: ticketIDRDocument?.ticketIDRMultipleDocuments?.map(
              (document) => ({
                ...document,
                fileStorageReference: document?.idrDocumentURL,
              })
            ),
          })
        ),
        idrQuestions: ticketQuestionsList?.answers || [],
      };
      reset({ ...editTicketDetails });
    }
  }, [isTicketDataLoading, isTicketsQuestionListLoading]);

  /**
   * useEffect hook that updates the "status" value in the form state based on the ticketData status.
   * @param {Function} setValue - Function to set the value in the form state.
   * @param {boolean} isRefetching - Boolean flag indicating whether data is being refetched.
   * @param {Object} ticketData - Object containing ticket data with a "status" property.
   * @returns None
   */
  useEffect(() => {
    if (id && ticketData) {
      setValue("status", {
        label: ticketData?.status,
        value: ticketData?.status,
      });
    }
  }, [isRefetching]);

  const documentTypeWatch = watch("ticketIDRDocuments");

  /**
   * Maps over the documentTypeWatch array and creates a new array of objects with updated values.
   */
  const selectedDocumentType = documentTypeWatch?.map((item) => ({
    ...item,
    value:
      documentTypeList?.find(
        (docType) => docType.idrDocument === item.documentName
      )?.idrDocumentID || item.documentName,
    label: item.documentName,
  }));

  /**
   * Filters the document list based on the presence of ticketIDRDocumentID in each item.
   */
  const filteredDocumentList = documentTypeWatch?.filter(
    (item) => item.ticketIDRDocumentID
  );

  const isCancelDisabled =
    type === "edit" && filteredDocumentList?.length === 0;

  const isApiLoading = isCreateTicketLoading || isUpdateTicketLoading;

  return (
    <Layout disabled={isCancelDisabled}>
      {isTicketDataLoading ? (
        <Loader />
      ) : (
        <div className={styles.modalContainer}>
          <div className={styles.headingWrapper}>
            <p className={styles.modalHeader}>{modalHeader}</p>
            <img src={moduleImage} alt="module icon" />
          </div>
          <div style={{ height: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.formContainer}>
                <div className={styles.requestDetails}>
                  <p className={styles.formHeader}>Request Details</p>
                  <div className={styles.formFieldContainer}>
                    <FormConatiner
                      control={control}
                      type={type}
                      setValue={setValue}
                      watch={watch}
                      disabled={isApiLoading}
                    />
                    <Grid
                      container
                      spacing={5}
                      alignItems="flex-end"
                      justifyContent="space-between"
                    >
                      <Grid item xs={8} md={4} lg={4}>
                        <Controller
                          name="assignee"
                          control={control}
                          rules={{
                            required: "Assigned user is required",
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <SearchableDropDown
                              {...field}
                              placeholder="Select Assigned User"
                              label="Assigned User"
                              options={assigneeOption}
                              error={!!error}
                              avatarLabel
                              isMulti
                              emailLength={35}
                              labelLength={30}
                              precontent={
                                <PersonOutlinedIcon
                                  sx={{ color: "#171A1FFF" }}
                                />
                              }
                              auto
                              helperText={error ? error.message : null}
                              isLoading={isAllUserListLoading}
                              isDisabled={isApiLoading}
                            />
                          )}
                        />
                      </Grid>

                      {user?.RoleName === "Admin" && (
                        <Grid item xs={8} md={4} lg={4}>
                          <CustomButton
                            type="button"
                            size="medium"
                            onClick={() => navigate("/ticket/addUser")}
                          >
                            <PlusIcon />
                            Add User
                          </CustomButton>
                        </Grid>
                      )}
                      <Grid item xs={8} md={4} lg={4}>
                        <Controller
                          name="taxYear"
                          control={control}
                          rules={{
                            required: "Tax year is required"
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <SearchableDropDown
                              {...field}
                              placeholder="Select Tax Year"
                              label="Tax Year"
                              options={yearsArray}
                              precontent={
                                <CalendarIcon />
                              }
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className={styles.documentRequestedContainer}>
                  <p className={styles.formHeader}>Documents Requested</p>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={10} lg={8}>
                      <DocumentRequested
                        key={openDocumentTypeModal || openAddQuestionModal}
                        documentList={documentList}
                        questionList={questionList}
                        removeQuestion={removeQuestion}
                        control={control}
                        remove={remove}
                        setValue={setValue}
                        disabled={isApiLoading}
                      />
                    </Grid>
                  </Grid>
                  <div
                    className="buttonContainer"
                    style={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    <CustomButton
                      type="button"
                      size="medium"
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenDocumentTypeModal(true);
                      }}
                      disabled={isApiLoading}
                    >
                      <PlusIcon />
                      Add Documents
                    </CustomButton>
                    <CustomButton
                      type="button"
                      size="medium"
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenAddQuestionModal(true);
                      }}
                      disabled={isApiLoading}
                    >
                      <PlusIcon />
                      Add Questions
                    </CustomButton>

                    <div style={{ marginLeft: "auto" }}>
                      <BulkUpload
                        selectedDocumentType={documentTypeWatch}
                        documentTypeList={documentTypeList}
                        setValue={setValue}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.buttonContainer}>
                  <CustomButton
                    variant="text"
                    size="medium"
                    onClick={() => navigate(-1)}
                    disabled={isApiLoading || isCancelDisabled}
                    style={{ color: "#f00" }}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    size="medium"
                    disabled={isApiLoading || isDeleteTicketDocumentLoading}
                    style={{ width: "100px" }}
                  >
                    Save
                  </CustomButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {openDocumentTypeModal && (
        <DocumentTypeModal
          open={openDocumentTypeModal}
          handleClose={() => setOpenDocumentTypeModal(false)}
          setValue={setValue}
          selectedValue={selectedDocumentType}
          option={documentTypeList}
          isLoading={isDocumentTypeListLoading}
        />
      )}
      {openAddQuestionModal && (
        <AddQuestionModal
          open={openAddQuestionModal}
          handleClose={() => setOpenAddQuestionModal(false)}
          setValue={setValue}
          addedQuestionList={questionList}
        />
      )}
      <LoadingOverlay isLoading={isApiLoading} />
    </Layout>
  );
};

CreateOrEditTicket.propTypes = {
  type: PropTypes.oneOf(["edit", "add"]).isRequired,
};
