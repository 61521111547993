import React, { useEffect, useCallback, useState } from "react";
import Viewticket from "./Viewticket";
import ModuleWrapper from "../../../components/ModuleWrapper";
import Sidebar from "../../../layouts/Sidebar";
import CustomButton from "../../../components/Button";
import moduleImage from "../../../assets/Images/dashboard-images/frame.svg";
import {
  useDocumentListContext,
  UseReminderRequestContext,
  UseDocumentUpdateContext,
  useAuth,
  useTicket,
} from "../../../utils/hooks";
import { useNavigate, useParams } from "react-router-dom";
import LoadingOverlay from "../../../components/LoadingOverlay";

export default function Viewticketwrapper() {
  const navigate = useNavigate();
  // for toggle button.
  const [toggleContent, setToggleContent] = useState(false);

  const { user } = useAuth();
  const { setStoreTableData, useGetTicketById } = useDocumentListContext();

  const {
    handleReminderRequestsData,
    modalOpen,
    setModalOpen,
    setAutoReminderData,
  } = UseReminderRequestContext();
  const {
    handleDocumentUpdateData,
    handleCheckboxUpdateData,
    isCheckboxUpdateDataLoading,
    isDocumentUpdateDataLoading,
  } = UseDocumentUpdateContext();

  const {
    useGetTicketQuestions,
    mutateUpdateQuestionAnswers,
    isUpdateQuestionAnswersLoading,
  } = useTicket();

  const { id } = useParams();
  const { data, isLoading, isRefetching } = useGetTicketById(id);

  const { data: ticketQuestionsList, isLoading: isTicketsQuestionListLoading } =
    useGetTicketQuestions(id);

  // first use effect has been used to organise the sample data into a key and value pair and later storing the entire dataset into the state.
  useEffect(() => {
    const tableData =
      data?.ticketIDRDocuments?.map((ticket) => ({
        document_type: ticket?.documentName,
        elsewhere: ticket?.elsewhere,
        idrDocumentReceived: ticket?.idrDocumentReceived,
        ticketIDRDocumentID: ticket?.ticketIDRDocumentID,
        tickectIDRDocumentVersions: ticket?.ticketIDRMultipleDocuments?.map(
          (version) => ({
            fileReference: version.fileName,
            fileURL: version.idrDocumentURL,
          })
        ),
      })) || [];
    const questionList = ticketQuestionsList?.answers || [];

    setStoreTableData([...tableData, ...questionList]);
  }, [data, ticketQuestionsList]);

  /**
   * function to handle the received elsewhere checkboxes status and pass a function with the required params for calling the document
   * edit put api.
   */

  const handleCheckboxData = (tableData, checkedValue) => {
    const checkStatus = checkedValue;
    const payload = [];
    payload.push({
      received: checkStatus,
      elsewhere: checkStatus,
      isActive: true,
      ticketDocumentId: tableData.ticketIDRDocumentID,
    });
    const requestBody = { list: payload };
    handleCheckboxUpdateData(requestBody, id);
    /**
     * calling the below handleform data function here to auto approved the checkbox once the received elsehwere checkbox is checked.
     * @return none
     */
    // handleFormData(tableData, checkedValue);
  };

  // handle form data for the document requested checkboxes functionalities.
  const handleFormData = (ticketData, checked) => {
    const isChecked = checked;
    const payload = [];
    const receivedElseWherePayload = [];
    payload.push({
      received: isChecked,
      elsewhere:
        ticketData.tickectIDRDocumentVersions?.length === 0 ? true : false,
      isActive: true,
      ticketDocumentId: ticketData.ticketIDRDocumentID,
    });
    const requestBody = { list: payload };
    const questionActionData = {
      questionID: ticketData.questionID,
      question: ticketData.question,
      ticketID: ticketData.ticketID,
      answers: ticketData.answers,
      answered: ticketData.answered,
      isActive: true,
      accepted: checked,
    };

    if (ticketData.ticketIDRDocumentID) {
      handleDocumentUpdateData(requestBody, id);
    } else {
      mutateUpdateQuestionAnswers({ answers: [questionActionData] });
    }

    /**
     * added this check to determine if there are no documents uploaded and if the check box state is false,
     * then recall the handleCheckBoxUpdateData function with a new payload by passing the elsewhere key as false.
     * @returns none.
     */

    if (ticketData.tickectIDRDocumentVersions?.length === 0 && !checked) {
      receivedElseWherePayload.push({
        received: isChecked,
        elsewhere: false,
        isActive: true,
        ticketDocumentId: ticketData.ticketIDRDocumentID,
      });
      const requestBody = { list: receivedElseWherePayload };
      handleCheckboxUpdateData(requestBody, id);
    }
  };

  // to open the send reminder modal.
  const handleDocumentRequested = () => {
    setModalOpen(true);
  };

  // for the history of send reminder component to toggle.
  const handleToggleButton = useCallback(() => {
    setToggleContent(!toggleContent);
  }, [toggleContent]);

  // button styles used below on line 99.
  const buttonStyles = {
    position: "absolute",
    top: "28px",
    right: "80px",
    width: "100px",
  };

  const handleSendReminderButton = (data) => {
    const finalData = {
      reminderList: data
        ?.filter((item) => item.ticketIDRDocumentID && item.isChecked)
        ?.map((item) => ({
          idrDocumentName: item.documentType,
          IDRTiecketDocumentId: item.ticketIDRDocumentID,
          notes: item.notes,
        })),
      questionList: data
        ?.filter((item) => item.questionId && item.isChecked)
        ?.map((item) => ({
          question: item.question,
          questionId: item.questionId,
          notes: item.notes,
        })),
    };
    handleReminderRequestsData(finalData, id);
  };

  // to close the send reminder modal.
  const handleModalClose = () => {
    setModalOpen(false);
    setAutoReminderData((prevState) => ({
      ...prevState,
      dueDate: "",
      radioButtonValue: "",
      toggleCheckbox: false,
    }));
  };
  const isTicketCancelled = data?.status === "Cancelled";
  const isTicketCompleted = data?.status === "Completed";

  const assigneeList = data?.user?.map((item) => item.userId) || [];

  const isEdit =
    user?.RoleName === "Admin" ||
    data?.createdBy === Number(user?.UserID) ||
    assigneeList.includes(Number(user?.UserID));

  return (
    <ModuleWrapper moduleHeader="View Requests" moduleImage={moduleImage}>
      <CustomButton
        disabled={
          isLoading ||
          isTicketsQuestionListLoading ||
          isTicketCancelled ||
          isTicketCompleted ||
          !isEdit
        }
        style={buttonStyles}
        onClick={() => navigate(`/ticket/edit/${id}`)}
      >
        Edit
      </CustomButton>
      <Sidebar />
      <Viewticket
        data={data}
        readOnly={isTicketCancelled || !isEdit || isTicketCompleted}
        handleFormData={handleFormData}
        viewTicketLoader={isLoading || isTicketsQuestionListLoading}
        modalOpen={modalOpen}
        handleDocumentRequested={handleDocumentRequested}
        handleModalClose={handleModalClose}
        handleToggleButton={handleToggleButton}
        toggleContent={toggleContent}
        handleSendReminderButton={handleSendReminderButton}
        handleCheckboxData={handleCheckboxData}
      />
      <LoadingOverlay
        isLoading={
          isRefetching ||
          isCheckboxUpdateDataLoading ||
          isDocumentUpdateDataLoading ||
          isUpdateQuestionAnswersLoading
        }
      />
    </ModuleWrapper>
  );
}
