import Cookies from "js-cookie";

export const setStorageValue = (key, value, expires) => {
  Cookies.set(key, value, { expires });
};

export const getStorageValue = (key) => {
  const value = Cookies.get(key);
  return value;
};

export const clearData = () => {
  localStorage.removeItem("responseCode");
  localStorage.removeItem("forceResetResponseCode");
  localStorage.removeItem("user-details");
  sessionStorage.clear();
  localStorage.removeItem("org_name");
  Cookies.remove("access_token")
  window.location.reload();
};
