// Hook (use-auth.js)
import React, { useContext, createContext, useState, useEffect } from "react";
import {
  DeleteClientConatctData,
  createNewClient,
  createNewClientConatctList,
  editClientConatctData,
  editClientData,
  getAllClientContactName,
  getAllClientName,
  getClientByID,
  getFilteredClientList,
  getViewContactList,
} from "../../controllers/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { TokenExpiry } from "../Auth/TokenExpiry";
import { isTokenExpired } from "../../helper";
import PropTypes from "prop-types";

const ClientContext = createContext();

export const useClient = () => useContext(ClientContext);

function useProvideClient() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [roleName, setRoleName] = useState("");
  const [viewDetails, setViewDetails] = useState(false);
  const [clientId, setClientId] = useState(false);
  const [clientDataStored, setClientDataStored] = useState({});
  const [clientContact, setClientContact] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [clientArchiveErrorText, setClientArchiveErrorText] = useState("");

  /**
   * Custom hook that uses useQuery to fetch all client names.
   * @returns The result of the query, including data and status.
   */
  const useGetAllClientName = () =>
    useQuery(["AllclientName"], () => getAllClientName(), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => {
        TokenExpiry(data?.ResponseCode);
      },
      cacheTime: 0,
    });

  /**
   * Custom React query hook to fetch all client contact names based on the provided ID.
   * @param {number} id - The ID used to fetch client contact names.
   * @returns {useQuery} - The query object containing the data and status of the query.
   */
  const useGetAllClientContactName = (id) =>
    useQuery(["allClientContactName", id], () => getAllClientContactName(id), {
      enabled: !!id,
      select: (data) => data.data,
      cacheTime: 0,
      onSuccess: (data) => {
        TokenExpiry(data?.ResponseCode);
      },
    });

  /**
   * Custom hook to fetch a filtered client list based on search and filter criteria.
   * @param {number} page - The page number of the client list to fetch.
   * @param {number} count - The number of clients to fetch per page.
   * @param {string} searchValue - The search value to filter clients by.
   * @param {string} filterValue - The filter value to apply to the client list.
   * @returns The filtered client list based on the provided criteria.
   */
  const useGetAllClientList = (
    page,
    count,
    searchValue,
    filterValue,
    groupName
  ) =>
    useQuery(
      ["FilteredclientList", searchValue, filterValue, page, count, groupName],
      () =>
        getFilteredClientList(count, page, searchValue, filterValue, groupName),
      {
        enabled: true,
        select: (data) => data?.data,
        onSuccess: (data) => {
          TokenExpiry(data?.ResponseCode);
        },
        cacheTime: 0,
      }
    );

  /**
   * Custom hook to fetch client data by ID using query parameters.
   * @param {string} id - The ID of the client to fetch.
   * @returns The result of the query with the specified parameters.
   */
  const useGetClientByID = (id) =>
    useQuery(["clientByID", id], () => getClientByID(id), {
      enabled: !!id,
      select: (data) => data?.data,
      onSuccess: (data) => {
        TokenExpiry(data?.ResponseCode);
      },
      cacheTime: 0,
    });

  /**
   * Custom hook to fetch client data by contact ID using useQuery from React Query.
   * @param {string} contactId - The ID of the contact to fetch client data for.
   * @returns The result of the useQuery hook for fetching client data.
   */
  const useGetClientByContactID = (contactId) =>
    useQuery(
      ["clientContact", contactId],
      () => getViewContactList(contactId),
      {
        enabled: !!contactId,
        select: (data) => data?.data,
        onSuccess: (data) => {
          TokenExpiry(data?.ResponseCode);
        },
        cacheTime: 0,
      }
    );

  /**
   * Custom hook that uses mutation to create a new client.
   * @param {{function}} createNewClient - The function to create a new client.
   * @param {{object}} onSuccess - The function to execute on successful creation of a new client.
   * @param {{object}} onError - The function to execute on error during creation of a new client.
   * @returns {{object}} - An object containing mutate function and isLoading boolean.
   */
  const { mutate: mutateCreateClient, isLoading: isCreateClientLoading } =
    useMutation(createNewClient, {
      onSuccess: (data) => {
        queryClient.refetchQueries("FilteredclientList");
        queryClient.refetchQueries("AllclientName");
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else if (!data?.data.success) {
          toast.error(data?.data.errors[0]);
        } else {
          toast.success("Successfully created a Client");
          if (location.pathname.includes("/ticket")) {
            navigate(-1);
          } else {
            navigate("client");
          }
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });

  /**
   * Custom hook that uses a mutation to create a new client contact list.
   * @param {Function} createNewClientConatctList - The function to create a new client contact list.
   * @returns {{
   *   mutate: Function,
   *   isLoading: boolean
   * }} An object containing the mutate function to trigger the mutation and a boolean indicating if the mutation is in progress.
   */
  const {
    mutate: mutateCreateClientContactList,
    isLoading: isCreateClientContactListLoading,
  } = useMutation(createNewClientConatctList, {
    onSuccess: (data) => {
      if (isTokenExpired(data?.data?.ResponseCode)) {
        TokenExpiry(data?.data?.ResponseCode);
      } else if (data?.data.success) {
        if (location.pathname.includes("/ticket")) {
          navigate(-1);
        } else {
          navigate("client");
        }
        queryClient.refetchQueries("FilteredclientList");
      } else {
        toast.error(data.data.errors[0]);
      }
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  /**
   * Handles the creation of a client by extracting the client name, address and groupName from the provided data object,
   * then calls the mutateCreateClient function with the filtered data.
   * @param {{object}} data - The data object containing client information.
   */
  const handleCreateClient = (data) => {
    const filterData = {
      clientName: data?.clientName,
      address: data?.address,
      groupID: data?.groupID === "" ? 0 : data?.groupID,
      clientContacts: data?.contact.map((item) => ({
        lastName: item.lastName,
        email: item.email,
        firstName: item.firstName,
        phone: item.phone,
      })),
    };

    mutateCreateClient(filterData);
  };

  /**
   * Executes a mutation to delete client contact data and handles success and error cases accordingly.
   * @param {{DeleteClientConatctData}} DeleteClientConatctData - The mutation function to delete client contact data.
   * @returns None
   */
  const { mutate: mutateDeleteClient } = useMutation(DeleteClientConatctData, {
    onSuccess: (data) => {
      setOpenDeleteModal(false);
      if (isTokenExpired(data?.data?.ResponseCode)) {
        TokenExpiry(data?.data?.ResponseCode);
      } else if (data?.data.success) {
        toast.success("Client contact deleted successfully");
        queryClient.refetchQueries("AllclientName");
        queryClient.refetchQueries("clientByID");
        queryClient.refetchQueries("FilteredclientList");
      } else {
        setClientArchiveErrorText(`${data?.data.errors[0]}`);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /**
   * Handles the deletion of a client contact by updating the data object and then calling
   * the mutateDeleteClient function with the updated data.
   * @param {{object}} data - The data object representing the client contact to be deleted.
   */
  const handledeletetClientContact = (data) => {
    const finalData = {
      ...data,
      isActive: false,
      status: "Archived",
      id: data.clientID,
    };
    delete finalData.client;
    delete finalData.clientID;
    delete finalData.createdBy;
    delete finalData.email;
    delete finalData.updatedBy;

    mutateDeleteClient(finalData);
  };

  /**
   * Custom hook that uses mutation to edit client data and handles success and error cases.
   * @param {{Function}} editClientData - The function to edit client data.
   * @returns {{mutateEditClient: Function, isEditClientLoading: boolean}} An object containing the mutate function and a boolean indicating if the client is currently being edited.
   */
  const { mutate: mutateEditClient, isLoading: isEditClientLoading } =
    useMutation(editClientData, {
      onSuccess: (data) => {
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else if (data?.data.success) {
          toast.success("Client updated  successfully");
          queryClient.refetchQueries("FilteredclientList");
          queryClient.refetchQueries("AllclientName");
          queryClient.refetchQueries("clientList");
          queryClient.refetchQueries("ticketList");
        } else if (data?.data.errors.length > 0) {
          toast.error(`${data?.data.errors[0]}`);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });

  /**
   * Custom hook that uses mutation to edit client data and handles success and error cases.
   * @param {{Function}} editClientData - The function to edit client data.
   * @returns {{mutateEditClient: Function, isEditClientLoading: boolean}} An object containing the mutate function and a boolean indicating if the client is currently being edited.
   */
  const { mutate: mutateClientArchive, isLoading: isClientArchiveLoading } =
    useMutation(editClientData, {
      onSuccess: (data) => {
        setOpenDeleteModal(false);
        if (isTokenExpired(data?.data?.ResponseCode)) {
          TokenExpiry(data?.data?.ResponseCode);
        } else if (data?.data.success) {
          toast.success("Client deleted successfully");
          queryClient.refetchQueries("FilteredclientList");
          queryClient.refetchQueries("AllclientName");
          queryClient.refetchQueries("clientList");
        } else {
          setClientArchiveErrorText(data?.data?.errors[0]);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });

  /**
   * Handles editing a client by filtering out client contact data and mutating the client and client contact list.
   * @param {{object}} data - The data object containing client information.
   */
  const handleEditClient = (data) => {
    const contactList = data.contact.filter((item) => !item.clientContactID);

    const filterContactData = {
      contact: contactList.map((item) => {
        delete item?.clientContactID;
        return { ...item };
      }),

      id: data?.id,
    };

    const filterData = {
      id: data?.id,
      clientName: data?.clientName,
      address: data?.address,
      groupID: data?.groupID === "" ? 0 : data?.groupID,
      status: "Active",
      isActive: true,
    };

    // delete filterData.isActive

    mutateEditClient(filterData);

    mutateCreateClientContactList(filterContactData);
  };

  /**
   * Handles the deletion of a client by updating the client's status to "deleted" and setting isActive to false.
   * @param {{object}} data - The data object containing information about the client to be deleted.
   */
  const handleDeleteClient = (data) => {
    const filterData = {
      id: data?.id,
      clientName: data?.clientName,

      status: "Archived",
      isActive: false,
    };

    mutateClientArchive(filterData);
  };

  /**
   * Custom hook that uses a mutation to edit client contact data.
   * @param {{editClientConatctData}} mutateEditClientContact - The function to mutate the client contact data.
   * @param {{boolean}} isEditClientContactLoading - Flag indicating if the mutation is in progress.
   */
  const {
    mutate: mutateEditClientContact,
    isLoading: isEditClientContactLoading,
  } = useMutation(editClientConatctData, {
    onSuccess: (data) => {
      if (isTokenExpired(data?.data?.ResponseCode)) {
        TokenExpiry(data?.data?.ResponseCode);
      } else if (data?.data.success) {
        queryClient.refetchQueries("FilteredclientList");
        queryClient.refetchQueries("AllclientName");
        navigate("client");
        toast.success("Client Contact updated  successfully");
      } else if (data?.data.errors.length > 0) {
        toast.error(`${data?.data.errors[0]}`);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /**
   * Updates the client contact data and then sends a filtered version of the data to the server.
   * @param {{object}} data - The data object containing client contact information.
   */
  const handleEditClientContact = (data) => {
    data.contact[0].status = data.contact[0]?.isActive ? "Active" : "InActive";
    data.contact[0].isActive = true;

    const filterData = {
      contact: data.contact[0],
      id: data.contact[0].clientID,
    };
    delete filterData.contact.email;
    delete filterData.contact.client;
    delete filterData.contact.updatedBy;
    delete filterData.contact.clientID;
    delete filterData.contact.createdBy;

    mutateEditClientContact(filterData);
  };

  return {
    setRoleName,
    roleName,

    setViewDetails,
    viewDetails,
    handleCreateClient,
    clientId,
    setClientId,
    useGetClientByID,
    setClientContact,
    handleEditClient,
    handleEditClientContact,
    clientContact,
    // data,

    useGetAllClientList,
    handledeletetClientContact,
    useGetAllClientName,
    useGetClientByContactID,
    useGetAllClientContactName,
    handleDeleteClient,
    isCreateClientContactListLoading,
    isEditClientLoading,
    isCreateClientLoading,
    clientDataStored,
    setClientDataStored,
    isEditClientContactLoading,
    groupName,
    setGroupName,
    openDeleteModal,
    setOpenDeleteModal,
    clientArchiveErrorText,
    setClientArchiveErrorText,
    isClientArchiveLoading,
  };
}

export function ProvideClient({ children }) {
  const ClientData = useProvideClient();
  return (
    <ClientContext.Provider value={ClientData}>
      {children}
    </ClientContext.Provider>
  );
}
ProvideClient.propTypes = {
  children: PropTypes.node.isRequired,
};
