import React from "react";
import styles from "./View-ticket.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import Reminderssent from "./Reminders";
import Documentsrequested from "./Documents-requested";
import { BasicModal } from "../../../components/Modal/Modal";
import Modalcontent from "./Modalcontent";
import moment from "moment";
import { Loader } from "../../../components/Loader";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PropTypes from "prop-types";
import RenderField from "../../../components/RenderFields/RenderField";
import RememberMeOutlinedIcon from "@mui/icons-material/RememberMeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CalendarIcon from "../../../assets/Icons/CalendarIcon";

const Viewticket = React.memo(
  ({
    data,
    viewTicketLoader,
    readOnly,
    handleFormData,
    handleCheckboxData,
    modalOpen,
    handleModalClose,
    handleToggleButton,
    toggleContent,
    handleSendReminderButton,
    handleDocumentRequested,
  }) => {
    return (
      <section className={styles.viewTicketSectionWrapper}>
        {viewTicketLoader ? (
          <Loader />
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <div className={styles.requestDetailsContainer}>
                <h5>Request Details</h5>
                <Grid container spacing={4} justifyContent="space-between">
                  <Grid item xs={12} md={6} lg={4}>
                    <RenderField
                      value={data?.ticketID}
                      label="Ticket ID"
                      icon={
                        <RememberMeOutlinedIcon className={styles.formIcon} />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <RenderField
                      value={data?.client?.clientName}
                      label="Client Name"
                      icon={<PeopleOutlinedIcon className={styles.formIcon} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <RenderField
                      value={`${data?.clientContact?.firstName} ${data?.clientContact?.lastName}`}
                      label="Client Contact Person"
                      icon={
                        <RememberMeOutlinedIcon className={styles.formIcon} />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <RenderField
                      value={moment(data?.dueDate).format("MM/DD/YYYY")}
                      label="Due Date"
                      icon={<CalendarIcon />}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <RenderField
                      value={data?.status}
                      label="Status"
                      icon={
                        <LocalOfferOutlinedIcon className={styles.formIcon} />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <RenderField
                      value={data?.clientContact?.email}
                      label="Email Address"
                      icon={<EmailOutlinedIcon className={styles.formIcon} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <RenderField
                      value={data?.owner}
                      label="Assigned User"
                      icon={
                        <PersonOutlineOutlinedIcon
                          className={styles.userIcon}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <RenderField
                      value={data?.taxYear}
                      label="Tax Year"
                      icon={<CalendarIcon className={styles.userIcon} />}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={7}>
              <Documentsrequested
                handleFormData={handleFormData}
                handleCheckboxData={handleCheckboxData}
                readOnly={readOnly}
                ticketData={data}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={5}>
              <Reminderssent
                handleToggleButton={handleToggleButton}
                toggleContent={toggleContent}
                handleDocumentRequested={handleDocumentRequested}
                readOnly={readOnly}
              />
            </Grid>
          </Grid>
        )}

        <BasicModal open={modalOpen} maxWidth="lg">
          <Modalcontent
            handleModalClose={handleModalClose}
            handleSendReminderButton={handleSendReminderButton}
          />
        </BasicModal>
      </section>
    );
  }
);

Viewticket.propTypes = {
  data: PropTypes.object,
  viewTicketLoader: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleFormData: PropTypes.func,
  modalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleToggleButton: PropTypes.func,
  toggleContent: PropTypes.bool,
  handleSendReminder: PropTypes.func,
  handleTextArea: PropTypes.func,
  handleSendReminderButton: PropTypes.func,
  handleDocumentRequested: PropTypes.func,
};

export default Viewticket;
