import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TextInput } from "../../../components/InputBox";
import { SearchableDropDown } from "../../../components/SearchableDropdown";
import styles from "../CreateOrEditTicket/CreateTicketModal.module.scss";
import CustomButton from "../../../components/Button";
import { PlusIcon } from "../../../assets/Icons/PlusIcon";
import BasicDatePicker from "../../../components/DatePicker";
import { useClient, useMaster } from "../../../utils/hooks";
import { useNavigate } from "react-router-dom";
import { dropDownOptionArray } from "../../../utils/helper";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";

function FormConatiner({ control, type, setValue, watch, disabled }) {
  const { useGetAllClientName, useGetAllClientContactName } = useClient();
  const { useGetTicketStatus } = useMaster();
  const navigate = useNavigate();

  const selectedClient = watch("client");
  const { data: ticketStatusList, isLoading: isTicketStatusListLoading } =
    useGetTicketStatus();
  const { data: allClientListname, isLoading: isAllClientListLoading } =
    useGetAllClientName();
  const { data: allClientContact, isLoading: isAllClientContactLoading } =
    useGetAllClientContactName(selectedClient?.value);

  /**
   * Creates an array of contact name options from the results of all client contacts.
   */
  const contactNameOption =
    allClientContact?.results?.map((item) => ({
      label: item.name,
      value: item.id,
      email: item.email,
    })) || [];

  const clientNameOption = dropDownOptionArray(
    allClientListname?.results,
    "name",
    "id"
  );
  /**
   * Maps the ticket status list to an array of objects with label, value, and isDisabled properties.
  
   */
  const ticketStatusOption = ticketStatusList?.map((item) => ({
    label: item.status,
    value: item.status,
    isDisabled: item.status !== "Cancelled",
  }));

  return (
    <div className={styles.formFieldContainer}>
      <Grid
        container
        spacing={5}
        justifyContent={"start"}
        alignItems={"center"}
      >
        {type === "edit" && (
          <Grid item xs={8} md={4} lg={4}>
            <Controller
              name="ticketID"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  label="Ticket ID"
                  type="text"
                  size="medium"
                  {...field}
                  error={!!error}
                  disabled
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={8} md={4} lg={4}>
          <Controller
            name="client"
            control={control}
            rules={{
              required: "Client name is required",
            }}
            render={({ field, fieldState: { error } }) => (
              <SearchableDropDown
                {...field}
                placeholder="Select Client Name"
                label="Client Name"
                options={clientNameOption}
                onChange={(e) => {
                  field.onChange(e);
                  setValue("contactPerson", "");
                  setValue("emailId", "");
                }}
                isLoading={isAllClientListLoading}
                isDisabled={type === "edit" || disabled}
                precontent={<PeopleOutlinedIcon sx={{ color: "#171A1FFF" }} />}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={8} md={4} lg={4}>
          <Controller
            name="contactPerson"
            control={control}
            rules={{
              required: "Client contact person is required",
            }}
            render={({ field, fieldState: { error } }) => (
              <SearchableDropDown
                {...field}
                placeholder="Select Contact Person"
                label="Client Contact Person"
                options={contactNameOption}
                precontent={<PersonOutlinedIcon sx={{ color: "#171A1FFF" }} />}
                onChange={(e) => {
                  field.onChange(e);
                  setValue("emailId", e.email);
                }}
                isLoading={isAllClientContactLoading}
                isDisabled={disabled}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        {type === "add" && (
          <Grid item xs={8} md={4} lg={4}>
            <CustomButton
              sx={{ marginTop: "20px" }}
              type="button"
              onClick={() => navigate("/ticket/addClient")}
              size="medium"
              disabled={disabled}
            >
              <PlusIcon />
              Create Client
            </CustomButton>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        spacing={5}
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Grid item xs={8} md={4} lg={4}>
          <Controller
            name="dueDate"
            control={control}
            rules={{
              required: "Due date is required",
            }}
            render={({ field, fieldState: { error } }) => (
              <BasicDatePicker
                {...field}
                label="Due Date"
                placeholderText=" Select the Due Date"
                error={!!error}
                disabled={disabled}
                minDate={new Date()}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>

        <Grid item xs={8} md={4} lg={4}>
          <Controller
            name="status"
            control={control}
            rules={{
              required: "Status is required",
            }}
            render={({ field, fieldState: { error } }) => (
              <SearchableDropDown
                {...field}
                placeholder=" Select the Status"
                label="Status"
                options={ticketStatusOption}
                precontent={
                  <LocalOfferOutlinedIcon
                    sx={{
                      color: "#171A1FFF",
                    }}
                  />
                }
                error={!!error}
                isLoading={isTicketStatusListLoading}
                isDisabled={disabled || type === "add"}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={8} md={4} lg={4}>
          <Controller
            name="emailId"
            control={control}
            // rules={{
            //   required: "Email address is required",
            // }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                label="Email Address"
                type="text"
                size="medium"
                {...field}
                error={!!error}
                precontent={<EmailOutlinedIcon sx={{ color: "#171A1FFF" }} />}
                disabled
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}

FormConatiner.propTypes = {
  control: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["edit", "add"]).isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default FormConatiner;
